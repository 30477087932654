import React, { useState } from 'react';
import PercentageCalculator from './PercentageCalculator';

const PercentForm = () => {
  // State to store the inputs and results for each calculator
  // The key names correspond to the calculation types
  const [calculations, setCalculations] = useState({
    x_percent_of_y: { valueA: '', valueB: '', result: '' },
    percent_x_of_y: { valueA: '', valueB: '', result: '' },
    x_is_y_percent: { valueA: '', valueB: '', result: '' },
    x_increase_to_y: { valueA: '', valueB: '', result: '' },
    x_greater_than_y: { valueA: '', valueB: '', result: '' },
    increase_x_by_y: { valueA: '', valueB: '', result: '' },
    decrease_x_by_y: { valueA: '', valueB: '', result: '' }
  });

  // Handle calculation
  const handleCalculate = (calculationType, name, value) => {
    // Update state based on input changes
    const updatedCalculation = {
      ...calculations[calculationType],
      [name]: value,
    };

    // Perform the calculation based on the type and update the result
    let result = '';

    if (calculationType === 'x_percent_of_y') {
      if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
        result = (parseFloat(updatedCalculation.valueA) / 100 * parseFloat(updatedCalculation.valueB));
      }
    } else if (calculationType === 'percent_x_of_y') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = (parseFloat(updatedCalculation.valueA) / parseFloat(updatedCalculation.valueB));
          }
    } else if (calculationType === 'x_is_y_percent') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = (parseFloat(updatedCalculation.valueA) / parseFloat(updatedCalculation.valueB) * 100);
          }
    }  else if (calculationType === 'x_increase_to_y') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = ((parseFloat(updatedCalculation.valueB) - parseFloat(updatedCalculation.valueA)) / parseFloat(updatedCalculation.valueA) * 100);
          }
    } else if (calculationType === 'x_greater_than_y') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = ((parseFloat(updatedCalculation.valueA) - parseFloat(updatedCalculation.valueB)) / parseFloat(updatedCalculation.valueB) * 100);
          }
    } else if (calculationType === 'increase_x_by_y') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = (parseFloat(updatedCalculation.valueA) + parseFloat(updatedCalculation.valueB) * parseFloat(updatedCalculation.valueA) / 100);
          }
    } else if (calculationType === 'decrease_x_by_y') {
        if ((updatedCalculation.valueB !== '') && (updatedCalculation.valueA !== '')) {
            result = (parseFloat(updatedCalculation.valueA) - parseFloat(updatedCalculation.valueB) * parseFloat(updatedCalculation.valueA) / 100);
          }
    }

    // Update the state with the new result
    setCalculations({
      ...calculations,
      [calculationType]: { ...updatedCalculation, result },
    });
  };

  return (
    <div className="container px-4 px-lg-5">
      <PercentageCalculator
        calculationType="x_percent_of_y"
        valueA={calculations.x_percent_of_y.valueA}
        valueB={calculations.x_percent_of_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.x_percent_of_y.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="percent_x_of_y"
        valueA={calculations.percent_x_of_y.valueA}
        valueB={calculations.percent_x_of_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.percent_x_of_y.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="x_is_y_percent"
        valueA={calculations.x_is_y_percent.valueA}
        valueB={calculations.x_is_y_percent.valueB}
        onCalculate={handleCalculate}
        result={calculations.x_is_y_percent.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="x_increase_to_y"
        valueA={calculations.x_increase_to_y.valueA}
        valueB={calculations.x_increase_to_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.x_increase_to_y.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="x_greater_than_y"
        valueA={calculations.x_greater_than_y.valueA}
        valueB={calculations.x_greater_than_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.x_greater_than_y.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="increase_x_by_y"
        valueA={calculations.increase_x_by_y.valueA}
        valueB={calculations.increase_x_by_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.increase_x_by_y.result}
      />
      <br/>
      <PercentageCalculator
        calculationType="decrease_x_by_y"
        valueA={calculations.decrease_x_by_y.valueA}
        valueB={calculations.decrease_x_by_y.valueB}
        onCalculate={handleCalculate}
        result={calculations.decrease_x_by_y.result}
      />
    </div>
  );
};

export default PercentForm;
