import React from 'react';
import { Form, InputGroup, Row, Col, Card } from 'react-bootstrap';

const InlineInput = ({ value, onChange, name }) => (
    <Form.Control
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      style={{ width: '100px', display: 'inline-block', margin: '0px 5px' }}
      className="mx-1"
    />
  );

const PercentageCalculator = ({ calculationType, valueA, valueB, onCalculate, result }) => {
  const renderCalculationLabelAndInputs = () => {
    switch (calculationType) {
      case 'x_percent_of_y':
        return (
          <>
            What is <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> % of
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} />?
          </>
        );
      case 'percent_x_of_y':
        return (
          <>
            What % is <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> of
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} />?
          </>
        );
      case 'x_is_y_percent':
        return (
          <>
            If <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> is
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> % what is the total?
          </>
        );
      case 'x_increase_to_y':
        return (
          <>
            By what percentage does <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> need to increase to become
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} />?
          </>
        );
      case 'x_greater_than_y':
        return (
            <>
            By what percentage is <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> greater than
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} />?
            </>
        );
      case 'increase_x_by_y':
        return (
            <>
            Increase <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> by
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> percent
            </>
        );
      case 'decrease_x_by_y':
        return (
            <>
            Decrease <InlineInput name="valueA" value={valueA} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> by
            <InlineInput name="valueB" value={valueB} onChange={(e) => onCalculate(calculationType, e.target.name, e.target.value)} /> percent
            </>
        );
      default:
        return null;
    }
  };

  return (
    <Card style={{backgroundColor: '#EEEEEE'}}>
    <Form>
        <Form.Group>
            <Row>
                <Col xs={12} lg={8}>
                    <InputGroup>
                        <Form.Label style={{ display: 'flex', alignItems: 'center', margin: '15px' }}>
                        {renderCalculationLabelAndInputs()}
                        </Form.Label>
                    </InputGroup>
                </Col>
                <Col xs={4}>
                    <Form.Control
                    readOnly
                    value={result}
                    style={{ display: 'flex', alignItems: 'center', margin: '15px', width: 'calc(100% - 30px)' }}
                    />
                </Col>
            </Row>
        </Form.Group>
    </Form>
    </Card>
  );
};

export default PercentageCalculator;
