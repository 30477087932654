import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PercentForm from './components/PercentForm';

const App = () => {
  return (
    <div>
      <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <h1>Percentage Calculator</h1>
      </div>
      <br/>
      <PercentForm />
    </div>
  );
};

export default App;
